import React from 'react'
import RelatedCol3 from "../components/UI/RelatedCol3"


const RelatedPosts = ( props ) => (
	<div className="related container max-w-5xl mx-auto categories-container mb-12">
        <h3 className="related-title m-12">Σχετικά Άρθρα</h3>

        <div className="flex flex-wrap justify-between md:mx-6">
            { (props.relatedPostsList && props.relatedPostsList[0]) && (
				<RelatedCol3
					 post={ props.relatedPostsList[0] }
					 category={ props.relatedPostsList[0].categories.edges[0].node }
				/>
			)}
			{ (props.relatedPostsList && props.relatedPostsList[1]) && (
				<RelatedCol3
					 post={ props.relatedPostsList[1] }
					 category={ props.relatedPostsList[1].categories.edges[0].node }
				/>
			)}
			{ (props.relatedPostsList && props.relatedPostsList[2]) && (
				<RelatedCol3
					 post={ props.relatedPostsList[2] }
					 category={ props.relatedPostsList[2].categories.edges[0].node }
				/>
			)}
        </div>
    </div>
);

export default RelatedPosts;