import React from 'react'
import hLogo from "../images/enimeris-logo-website-white.png";



const ScrollingHeader = () => (
	<div id="scrollingHeader" className="bg-white fixed w-full z-10 pin-t hidden animated opacity-95 top-0">
		<div className="bg-white">
			<div className="flex flex-wrap items-center content-center px-4">
				<div className="flex w-1/2 justify-start text-white font-extrabold">
					<img className="w-40 m-auto block py-2 " src={hLogo} alt="horizontal-logo" />
				</div>
				{/* <div className="flex w-1/2 justify-end content-center flex sm:hidden ">		
					  <p className="mr-3 text-center h-14 p-4 text-xs"><span className="pr-2">Share this</span></p>
						<a className="inline-block text-white no-underline hover:text-white hover:text-underline text-center h-10 w-10 p-2 md:h-auto md:w-16 md:p-4" href="https://twitter.com/intent/tweet?url=#" >
							<img src="/public/images/facebook.svg" />
						</a>
						<a className="inline-block text-white no-underline hover:text-white hover:text-underline text-center h-10 w-10 p-2 md:h-auto md:w-16 md:p-4" href="https://www.facebook.com/sharer/sharer.php?u=#" >
							<img src="/public/images/pinterest.svg" />
						</a>
				</div> */}
			</div>
		</div>

		<div id="progress" className="h-1 bg-white shadow progress-bar"></div>
	</div>
);

export default ScrollingHeader;