import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from '../components/layout'
import ScrollingHeader from '../components/ScrollingHeader'
import RelatedPosts from '../components/RelatedPosts'
import htmlDecode from "html-entities-decoder";
import moment from "moment"


import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    EmailIcon
} from "react-share";
import { Helmet } from "react-helmet"


function BlogPageTest(props) {
    const {
        data: {
            wpgraphql: { postBy, footer, header, serviceCategories },
        },
    } = props;


    function preloadImage(img) {
        const src = img.getAttribute("data-src");
        if (!src) {
            return;
        }
        img.src = src;
    }

    function RemoveLastDirectoryPartOf(the_url) {
        var the_arr = the_url.split('/');
        the_arr.pop();
        if (the_arr.length === 4) {
            return (the_arr.join('/') + "/");
        } else {
            the_arr.pop();
            return (the_arr.join('/') + "/");
        }
    }

    function extractContent(s) {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
    };

    function extractContentH2(s) {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.querySelector("h2").innerText;
    };

    function getCitation(s) {
        var span = document.createElement('span');
        span.innerHTML = s;
        let aList = span.querySelectorAll("a");
        let aTmp = [];

        if (aList.length > 0) {
            let i;
            for (i = 0; i < aList.length; i++) {
                aTmp.push(aList[i].href)
            }

            let citation = {
                citation: [
                    aTmp.map((link) => {
                        return (     
                        {
                            "@type":"Webpage",
                            "relatedLink": `${link}`
                        }
                        )
                    })
                ],

            }

            
            let cit = JSON.stringify(citation)
            let result = cit.substring(1, cit.length-1);
            return result + ",";
        } else {
            return ""
        }
    }

    const imgOptions = {
        threshold: 0,
        rootMargin: "0px 0px 300px 0px"
    };

    useEffect(() => {
        /* Progress bar */
        //Source: https://alligator.io/js/progress-bar-javascript-css-variables/
        var h = document.documentElement,
            b = document.body,
            st = "scrollTop",
            sh = "scrollHeight",
            progress = document.querySelector("#progress"),
            related = document.getElementsByClassName("related"),
            scroll;

        var scrollpos = window.scrollY;
        var header = document.getElementById("scrollingHeader");
        var mainHeader = document.getElementById("headerContainer");
        var headerHack = true;

        function headerScroller() {
            /*Refresh scroll % width*/
            scroll = ((h[st] || b[st]) + (related[0].scrollHeight)) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
            progress.style.setProperty("--scroll", scroll + "%");

            /*Apply classes for slide in bar*/
            scrollpos = window.scrollY;

            if (scrollpos > 300 && headerHack) {
                header.classList.remove("hidden");
                header.classList.remove("fadeOutUp");
                header.classList.add("slideInDown");
                mainHeader.classList.add("hidden");
                headerHack = false;
            }

            if (scrollpos === 0 && !headerHack) {
                header.classList.remove("slideInDown");
                header.classList.add("fadeOutUp");
                header.classList.add("hidden");
                mainHeader.classList.remove("hidden");
                headerHack = true;
            }
        }

        document.addEventListener("scroll", headerScroller);

        let imgObserver = new IntersectionObserver((entries, imgObserver) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                } else {
                    preloadImage(entry.target);
                    imgObserver.unobserve(entry.target);
                }
            })
        }, imgOptions)

        let images = document.querySelectorAll("[data-src]");
        images.forEach(image => {
            imgObserver.observe(image)
        })


        return () => {
            document.removeEventListener("scroll", headerScroller);
        }

    }, [])


    function addLazyLoadHandler(content) {
        let lazyLoadedContent;
        lazyLoadedContent = content.replace(/src="/g, 'data-src="')
        lazyLoadedContent = lazyLoadedContent.replace(/srcset="/g, 'data-srcset="')
        return lazyLoadedContent;
    }


    function constructMetaData(postBy) {
        let payload = {
            title: htmlDecode(postBy.seo.title),
            description: postBy.seo.metaDesc,
            canonical: `https://blog.enimeris.com/${props.pageContext.category.slug}/${postBy.slug}/`,
            fbTitle: postBy.seo.opengraphTitle ? postBy.seo.opengraphTitle : postBy.seo.title,
            fbDescription: postBy.seo.opengraphDescription ? postBy.seo.opengraphDescription : postBy.seo.metaDesc,
            fbUrl: `https://blog.enimeris.com/${props.pageContext.category.slug}/${postBy.slug}`,
            fbType: "website",
            locale: "el",
            image: postBy.seo.opengraphImage ? postBy.seo.opengraphImage.sourceUrl : postBy.featuredImage.sourceUrl
        }
        return payload
    }

    return (
        <Layout metaData={constructMetaData(postBy)} header={header} footer={footer} services={serviceCategories} >

            { typeof window != "undefined" && (
                <Helmet>
                    <script type="application/ld+json">
                        {`{
                        "@context": "https://schema.org",

                        "@type": "NewsArticle",

                        "url": "${props.location.href}",
                        //the URL of each article

                        "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "${ RemoveLastDirectoryPartOf(props.location.href)}"
                            },

                        "articleSection" : "Επικαιρότητα",

                        "publisher" :{
                            "@type":"Organization",
                            "name":"Enimeris",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://f2.enimeris.com/wp-content/uploads/2020/08/ENIMERIS-RGB_FULL-COLOR.png"
                                //check if the Wordpress URL is OK to be shown
                            }
                        },

                        "copyrightHolder": {
                            "@type":"Organization",
                            "name":"Enimeris",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://f2.enimeris.com/wp-content/uploads/2020/08/ENIMERIS-RGB_FULL-COLOR.png"
                                //check if the Wordpress URL is OK to be shown
                            }
                        },


                        "author" :{
                            "@type":"Organization",
                            "name":"Enimeris",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://f2.enimeris.com/wp-content/uploads/2020/08/ENIMERIS-RGB_FULL-COLOR.png"
                                //check if the Wordpress URL is OK to be shown
                            }
                        },

                        "headline": "${htmlDecode(postBy.title)}",
                        //the headline (probably h1) of each article

                        "abstract": "${postBy.excerpt.replace("<p>","").replace("</p>","")}",
                        // equal to excerpt


                        "articleBody": "${extractContent(postBy.content)}",
                        //you could include here only the headings and the paragraphs and avoid including the images etc as the Expected Type is text

                        "wordCount": ${extractContent(postBy.content).length},
                        //the total words of each article

                        "image":[
                            "https://blog.enimeris.com${postBy.featuredImage.imageFile.childImageSharp.fluid.src}"
                            //what about the URLs from f2.enimeris?
                        ],

                        "datePublished":"${moment(postBy.date ).format("DD-MM-YYYY")}",
                        //the published day of each article

                        ${postBy.date !== postBy.modified ? `"datemodified": "${moment(postBy.modified ).format("DD-MM-YYYY")}"` : ""},
                        //only if it is different from the published day

                        ${getCitation(postBy.content)}

                        "thumbnailUrl": "https://blog.enimeris.com${postBy.featuredImage.imageFile.childImageSharp.fluid.srcSet.split(" ")[0]}"
                        }`}
                    </script>
                </Helmet>
            )}

            <ScrollingHeader />
            <div className="container mx-auto ">
                <div className="mx-0">
                    <div className="bg-white w-full pb-8 md:pb-24 md:pt-12 text-xl md:text-2xl text-grey-darkest leading-normal" >
                        <div className="title-flex flex max-w-5xl mx-auto">
                            <div className="title-container" >
                                <div className="text-lg">
                                    <span className="pr-2 uppercase">
                                        {postBy.categories.nodes[0].name.replace(/ά/g,'α').replace(/έ/g,'ε').replace(/ή/g,'η').replace(/ί/g,'ι').replace(/ώ/g,'ω').replace(/ό/g,'ο').replace(/ύ/g,'υ')}
                                    </span>
                                    <span>|</span>
                                    <span className="pl-2">{postBy.postRT.postReadTime} ΛΕΠΤΑ</span>
                                </div>
                                <div className="post-title">
                                    <h1>
                                        {htmlDecode(postBy.title)}
                                    </h1>
                                </div>

                                <div className="block" style={{color: "gray"}}>
                                    <div className="text-lg">Δημιουργήθηκε: {moment(postBy.date ).format("DD/MM/YYYY")}</div>
                                    { moment(postBy.date ).format("DD/MM/YYYY") !== moment(postBy.modified ).format("DD/MM/YYYY") && (
                                        <div className="text-lg">Ανανεώθηκε: {moment(postBy.modified ).format("DD/MM/YYYY")}</div>
                                    )}
                                    
                                </div>
                                

                                {/* <div className="mt-12 flex items-center">
									<div className="post-author-tag-image-container">
										<img className="post-author-image" src={postBy.author.avatar.url} />
									</div>
									<div className="text-base">
										Written by {postBy.author.name}
									</div>
								</div> */}
                                <div>

                                </div>
                            </div>
                            <div className="image-container w-full" >
                                {postBy.featuredImage && (
                                    <Img
                                        fluid={postBy.featuredImage.imageFile.childImageSharp.fluid}
                                        alt={postBy.featuredImage.altText}
                                        className=" w-full rounded-t pb-6"
                                        style={{ height: "380px" }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="sticky-container hidden lg:block">
                        <div>
                            <FacebookShareButton url={`https://blog.enimeris.com/${props.pageContext.category.slug}/${postBy.slug}/`}>
                                <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>

                            <WhatsappShareButton url={`https://blog.enimeris.com/${props.pageContext.category.slug}/${postBy.slug}/`}>
                                <WhatsappIcon size={32} round={true} />
                            </WhatsappShareButton>

                            <EmailShareButton url={`https://blog.enimeris.com/${props.pageContext.category.slug}/${postBy.slug}/`}>
                                <EmailIcon size={32} round={true} />
                            </EmailShareButton>
                        </div>
                    </div>
                    <div className="csm-border-blog">
                        <div dangerouslySetInnerHTML={{ __html: addLazyLoadHandler(postBy.content) }} />
                    </div>
                </div>
                <div className="related">
                    {postBy.relatedPosts && (
                        <RelatedPosts relatedPostsList={postBy.relatedPosts.relatedPosts} />
                    )}
                </div>

            </div>

        </Layout>
    )
}

export default BlogPageTest

export const pageQuery = graphql`
    query GET_POST($id: ID!) {
        wpgraphql {
            footer: menu(id:"bmF2X21lbnU6MjQ="){
                menuId
                id
                slug
                name
                menuItems {
                    edges {
                        node {
                            label
                            url
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
    
            header: menu(id:"bmF2X21lbnU6MjI="){
                menuId
                id
                slug
                name
                menuItems {
                    edges {
                        node {
                            label
                            url
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
            serviceCategories(first: 100, where: {language: EL}) {
                edges {
                    node {
                        name
                        slug
                        language {
                            code
                        }
                        services {
                            edges {
                                node {
                                    uri
                                    title
                                }
                            }
                        }
                    }
                }
            }
            postBy(id: $id) {
                slug
                title
                content
                excerpt
                date
                modified
                author {
                    name
                    avatar {
                        url
                    }
                }
                seo {
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                featuredImage {
					sourceUrl
					altText
					imageFile {
					  childImageSharp {
					  fluid{
						base64
						aspectRatio
						src
						srcSet
						srcWebp
						srcSetWebp
						sizes
					  }
					  }
					}
				}
                postRT{
                    postReadTime
                }
                categories{
                    nodes{
                        slug
                        name
                    }
                }
                relatedPosts {
                    relatedPosts {
                        ... on WPGraphQL_Post {
                            slug
                            title
                            featuredImage{
                                sourceUrl
								altText
								imageFile {
									childImageSharp {
										fluid(maxWidth: 615, quality:80){
										base64
										aspectRatio
										src
										srcSet
										srcWebp
										srcSetWebp
										sizes
										}
									}
								}
							}
							categories {
								edges {
								  node {
									slug
									name
								  }
								}
							}
                        }
                    }
                }
            }

        }
    }
`
